import {createStore} from 'vuex'
import axios from "axios";

const {VUE_APP_LINK} = process.env
export default createStore({
    state: {
        cars: [],
        pos6: [],
        partNames: [],
        eventsCars: [],
        winnerBox: [],
        avatars: [],
        clothsShop: [],
        getDefaultUserSetList: []
    },
    mutations: {
        getCars(state, payload) {
            state.cars = payload
        },
        getEventsCars(state, payload) {
            state.eventsCars = payload
        },
        getPos6(state, payload) {
            state.pos6 = payload
        },
        getPartNames(state, payload) {
            state.partNames = payload
        },
        getPartNameById(state, payload) {
            state.selectedPartName = payload
        },
        getWinnerBox(state,payload){
            state.winnerBox = payload
        },
        getAvatars(state, payload){
            state.avatars = payload
        },
        getClothsShop(state,payload){
            state.clothsShop = payload
        },
        getPartsShop(state,payload){
            state.partsShop = payload
        },
        getDefaultUserSetList(state,payload){
            state.getDefaultUserSetList = payload;
        }
    },
    actions: {
        async getCars(context) {

            let result = await axios.post(`${VUE_APP_LINK}/getShopCars`);
            context.commit('getCars', result.data.data)
        },
        async getEventsCars(context) {

            let result = await axios.post(`${VUE_APP_LINK}/getEventsCars`);
            context.commit('getEventsCars', result.data.data)
        },
        async addNewCarInShop(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/addNewCarToShop`, payload)
            console.log(result)
            context.commit('getCars', result.data.data)
        },
        async addNewCarInEvents(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/addNewCarToEvents`, payload)
            console.log(result)
            context.commit('getEventsCars', result.data.data)
        },

        async getPos6(context) {
            let result = await axios.post(`${VUE_APP_LINK}/getPos6`);
            context.commit('getPos6', result.data.data)
            console.log(result)
        },

        async addNewPos6Type(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/setPos6`, payload)
            console.log(result)
            context.commit('getPos6', result.data.data)
        },
        async getPartNames(context) {
            let result = await axios.post(`${VUE_APP_LINK}/getPartNameData`);
            context.commit('getPartNames', result.data.data)
            console.log(result)
        },

        async addPartName(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/setPartNameData`, payload)
            console.log(result)
            context.commit('getPartNames', result.data.data)
        },


        async getPartNameById(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/getPartNamesById`, {id: payload.id});
            context.commit('getPartNameById', result.data.data)
            console.log(result)
        },
        async updatePartNameData(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/updatePartNameData`, {
                id: payload.id,
                partName: payload.partName
            });
            context.commit('getPartNames', result.data.data)
            console.log(result)
        },
        async deletePartName(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/deletePartName`, {id: payload.id});
            context.commit('getPartNames', result.data.data)
            console.log(result)
        },
        async updatePos6(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/updatePos6Data`, {
                id: payload.id,
                partPlace: payload.partPlace
            });
            context.commit('getPos6', result.data.data)
            console.log(result)
        },
        async deletePos6(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/deletePos6`, {id: payload.id});
            context.commit('getPos6', result.data.data)
            console.log(result)
        },
        async deleteCar(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/deleteShopCar`, {id: payload.id});
            context.commit('getCars', result.data.data)
        },
        async updateCar(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/updateCarInShop`, payload);
            context.commit('getCars', result.data.data)
            console.log(result)
        },
        async deleteEventsCar(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/deleteEventsCar`, {id: payload.id});
            context.commit('getEventsCars', result.data.data)
        },
        async updateEventsCar(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/updateCarInEvents`, payload);
            context.commit('getEventsCars', result.data.data)
            console.log(result)
        },
        async copyCarToEvents(context, payload) {
            let result = await axios.post(`${VUE_APP_LINK}/copyCarFromShopToEvents`, payload);
            context.commit('getEventsCars', result.data.data)
            console.log(result)
        },

        async deleteWinnerBox(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/deleteWinnerBoxData`, {id: payload.id});
            context.commit('getWinnerBox', result.data.data)
        },

        async updateWinnerBox(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/updateWinnerBoxData`, payload);
            context.commit('getWinnerBox', result.data.data)
            console.log(result)
        },

        async addWinnerBox(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/setWinnerBoxData`, payload);
            context.commit('getWinnerBox', result.data.data)
        },

        async getWinnerBox(context) {
            let result = await axios.post(`${VUE_APP_LINK}/getWinnerBoxData`);
            context.commit('getWinnerBox', result.data.data)
        },
        async getAvatars(context){
            let result = await axios.post(`${VUE_APP_LINK}/getAvatarData`);
            context.commit('getAvatars', result.data.data)
        },
        async addNewAvatar(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/setAvatarData`, payload);
            context.commit('getAvatars', result.data.data)
        },
        async updateAvatar(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/updateAvatarData`,payload);
            context.commit('getAvatars', result.data.data)
        },
        async deleteAvatars(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/deleteAvatarData`,{id: payload.id});
            context.commit('getAvatars', result.data.data)
        },

        async getClothsShop(context){
            let result = await axios.post(`${VUE_APP_LINK}/getClothsShopData`);
            context.commit('getClothsShop', result.data.data)
        },
        async addNewClothsShop(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/setClothsShopData`, payload);
            context.commit('getClothsShop', result.data.data)
        },
        async updateClothsShop(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/updateClothsShopData`,payload);
            context.commit('getClothsShop', result.data.data)
        },
        async deleteClothsShop(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/deleteClothsShopData`,{id: payload.id});
            context.commit('getClothsShop', result.data.data)
        },

        async getPartsShop(context){
            let result = await axios.post(`${VUE_APP_LINK}/getPartsShopData`);
            context.commit('getPartsShop', result.data.data)
        },
        async addNewPartShop(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/setPartsShopData`, payload);
            context.commit('getPartsShop', result.data.data)
        },
        async updatePartsShop(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/updatePartsShopData`,payload);
            context.commit('getPartsShop', result.data.data)
        },
        async deletePartsShop(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/deletePartsShopData`,{id: payload.id});
            context.commit('getPartsShop', result.data.data)
        },

        async getDefaultUserSetList(context){
            let result = await axios.post(`${VUE_APP_LINK}/getDefaultIntroList`);
            context.commit('getDefaultUserSetList', result.data.data)
        },
        async addNewDefaultUserSetList(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/addNewDataToDefaultIntroList`, payload);
            context.commit('getDefaultUserSetList', result.data.data)
        },
        async updateDefaultUserSetList(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/updateDataInDefaultIntroList`,payload);
            context.commit('getDefaultUserSetList', result.data.data)
        },
        async deleteDefaultUserSetList(context,payload){
            let result = await axios.post(`${VUE_APP_LINK}/deleteDefaultIntroList`,{id: payload.id});
            context.commit('getDefaultUserSetList', result.data.data)
        },
    },
    modules: {}
})
