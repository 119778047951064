<template>
  <h1>List of cars in shop</h1>
  <el-table
    style="width: 100%!important;"
    max-height="100%"
    :data="cars"
  >
    <el-table-column
      prop="id"
      label="Id"
      width="100%"
    />
    <el-table-column
      prop="carID"
      label="Car ID"
      width="100%"
    />
    <el-table-column
      prop="carModel"
      label="Name"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].credits"
      label="Credits"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].cash"
      label="Cash"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].saleCredits"
      label="Sale Credits"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].saleCash"
      label="Sale Cash"
      width="100%"
    />
    <el-table-column
      label="Actions"
      width="100%"
    >
      <template #default="scope">
        <el-button
          type="text"
          size="small"
          @click.prevent="copyToEvents(scope.row)"
        >
          Copy to Events
        </el-button>
        <el-button
          type="text"
          size="small"
          @click.prevent="editData(scope.row)"
        >
          Edit
        </el-button>
        <el-button
          type="text"
          size="small"
          @click.prevent="deleteRow(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <h1>List of cars in events</h1>

  <el-table
    style="width: 100%!important;"
    max-height="100%"
    :data="eventCars"
  >
    <el-table-column
      prop="id"
      label="Id"
      width="100%"
    />
    <el-table-column
      prop="carID"
      label="Car ID"
      width="100%"
    />
    <el-table-column
      prop="carModel"
      label="Name"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].credits"
      label="Credits"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].cash"
      label="Cash"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].saleCredits"
      label="Sale Credits"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].saleCash"
      label="Sale Cash"
      width="100%"
    />
    <el-table-column
      label="Actions"
      width="100%"
    >
      <template #default="scope">
        <el-button
          type="text"
          size="small"
          @click.prevent="editEventsCar(scope.row)"
        >
          Edit
        </el-button>
        <el-button
          type="text"
          size="small"
          @click.prevent="deleteEventsCar(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>


  <h1>Avatars</h1>

  <el-table
    style="width: 100%!important;"
    max-height="100%"
    :data="avatars"
  >
    <el-table-column
      prop="id"
      label="Id"
      width="100%"
    />
    <el-table-column
      prop="carID"
      label="Car ID"
      width="100%"
    />
    <el-table-column
      prop="carModel"
      label="Name"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].credits"
      label="Credits"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].cash"
      label="Cash"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].saleCredits"
      label="Sale Credits"
      width="100%"
    />
    <el-table-column
      prop="buyData[0].saleCash"
      label="Sale Cash"
      width="100%"
    />
    <el-table-column
      label="Actions"
      width="100%"
    >
      <template #default="scope">
        <el-button
          type="text"
          size="small"
          @click.prevent="editAvatar(scope.row)"
        >
          Edit
        </el-button>
        <el-button
          type="text"
          size="small"
          @click.prevent="deleteAvatar(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>



  <h1>Default set list</h1>


  <el-table
      style="width: 100%!important;"
      max-height="100%"
      :data="getDefaultUserSetList"
  >
    <el-table-column
        prop="id"
        label="Id"
        width="100%"
    />
    <el-table-column
        label="Actions"
        width="100%"
    >
      <template #default="scope">
        <el-button
            type="text"
            size="small"
            @click.prevent="editDefaultUserSetList(scope.row)"
        >
          Edit
        </el-button>
        <el-button
            type="text"
            size="small"
            @click.prevent="deleteDefaultUserSetList(scope.row)"
        >
          Delete
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      tableData:[{
        carId:1,
        name:"Hello world"
      },{
        carId: 2,
        name: "Jetix"
      }]
    };
  },
  computed: {
    cars () {
      return this.$store.state.cars
    },
    eventCars(){
      return this.$store.state.eventsCars
    },
    avatars() {
      return this.$store.state.avatars
    },
    getDefaultUserSetList(){
      return this.$store.state.getDefaultUserSetList
    }
  },
  mounted() {
    this.$store.dispatch("getCars")
    this.$store.dispatch("getEventsCars")
    console.log(this.$store.cars)
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    editData(row){
      this.$router.push({name: 'Cars', params: {id: row.id}})
    },
    deleteRow(row){
        this.$store.dispatch('deleteCar',{id: row.id})
    },
    copyToEvents(row){
      this.$store.dispatch('copyCarToEvents',{id: row.id})
    },
    deleteEventsCar(row){
      this.$store.dispatch('deleteEventsCar',{id: row.id})
    },
    editEventsCar(row){
      this.$router.push({name: 'EventsCars', params: {id: row.id}})
    },

    deleteAvatar(row){
      this.$store.dispatch('deleteAvatars',{id: row.id})
    },

    editAvatar(row){
      this.$router.push({name: 'Avatars', params: {id: row.id}})
    },

    deleteDefaultUserSetList(row){
      this.$store.dispatch('deleteAvatars',{id: row.id})
    },

    editDefaultUserSetList(row){
      this.$router.push({name: 'DefaultUserSetList', params: {id: row.id}})
    }
  }
}
</script>

<style>
table{
  width: 100%!important;
}
</style>