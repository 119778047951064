<template>
  <div>
    <el-container>
      <el-header>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <router-link
            to="/"
            style="text-decoration: none"
          >
            <el-menu-item
              index="1"
            >
              Home
            </el-menu-item>
          </router-link>
          <router-link
            to="/cars"
            style="text-decoration: none"
          >
            <el-menu-item
              index="2"
            >
              Cars
            </el-menu-item>
          </router-link>
          <router-link
            to="/pos6"
            style="text-decoration: none"
          >
            <el-menu-item
              index="3"
            >
              Pos6
            </el-menu-item>
          </router-link>
          <router-link
            to="/part-names"
            style="text-decoration: none"
          >
            <el-menu-item
              index="3"
            >
              Part Names
            </el-menu-item>
          </router-link>
          <router-link
            to="/events-cars"
            style="text-decoration: none"
          >
            <el-menu-item
              index="4"
            >
              Events Cars
            </el-menu-item>
          </router-link>
          <router-link
            to="/winner-box"
            style="text-decoration: none"
          >
            <el-menu-item
              index="5"
            >
              Winner Box
            </el-menu-item>
          </router-link>
          <router-link
            to="/avatars"
            style="text-decoration: none"
          >
            <el-menu-item
              index="6"
            >
              Avatars
            </el-menu-item>
          </router-link>
          <router-link
            to="/clothsShop"
            style="text-decoration: none"
          >
            <el-menu-item
              index="7"
            >
              Cloths shop
            </el-menu-item>
          </router-link>
          <router-link
              to="/partsShop"
              style="text-decoration: none"
          >
            <el-menu-item
                index="8"
            >
              Parts shop
            </el-menu-item>
          </router-link>
          <router-link
              to="/defaultUserSetList"
              style="text-decoration: none"
          >
            <el-menu-item
                index="8"
            >
              DefaultUserSetList
            </el-menu-item>
          </router-link>
        </el-menu>
      </el-header>
      <el-main><router-view /></el-main>
      <el-footer>Footer</el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    };
  },
  mounted() {
    this.$store.dispatch('getPos6')
    this.$store.dispatch('getPartNames')
    this.$store.dispatch('getWinnerBox')
    this.$store.dispatch('getAvatars')
    this.$store.dispatch('getClothsShop')
    this.$store.dispatch('getPartsShop')
    this.$store.dispatch('getDefaultUserSetList')
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
}
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
</style>